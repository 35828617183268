var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[(_vm.initTag && _vm.initTag.id)?_c('md-card',[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"blue"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v("Изменить тег")])])]),_c('ValidationObserver',{ref:"editTagForm",attrs:{"slim":true}},[_c('md-card-content',[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Название")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.title),callback:function ($$v) {_vm.title=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"title"}})],1)]}}],null,false,3901533085)}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Ключ")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.key),callback:function ($$v) {_vm.key=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"key"}})],1)]}}],null,false,2695353514)}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Порядок")]),_c('md-input',{attrs:{"type":"number","min":"0"},on:{"focus":reset,"change":_vm.validateOrder},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}})],1)]}}],null,false,98178064)}),_c('div',{staticClass:"buttons-wrap"},[_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.editTag}},[_vm._v(" Сохранить ")])],1)],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }