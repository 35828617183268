<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card v-if="initTag && initTag.id">
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Изменить тег</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="editTagForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model.trim="title" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Ключ</label>
                <md-input v-model.trim="key" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Порядок</label>
                <md-input
                  v-model="order"
                  type="number"
                  min="0"
                  @focus="reset"
                  @change="validateOrder"
                />
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="editTag">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      title: null,
      key: null,
      order: null,
    };
  },

  computed: {
    ...mapState({
      initTag: (state) => state.tags.tag,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    let success = await this.getTag(+this.$route.params.id);
    if (success) {
      this.title = this.initTag.title;
      this.key = this.initTag.key;
      this.order = this.initTag.order;
    }
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("tags", ["getTag", "updateTag"]),

    async editTag() {
      const isValid = await this.$refs.editTagForm.validate();
      if (!isValid) {
        return;
      }

      let tag = {
        title: this.title,
        key: this.key,
        order: this.order,
      };

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateTag({
        id: this.initTag.id,
        tag,
      });

      if (success) {
        this.$router.push("/tags");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    validateOrder(event) {
      if (event.target.value < 0) {
        this.order = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>